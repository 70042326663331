import api from "api";
import  { LoaderWithScreen } from "Loader";
import {  MatchStreamer } from "match";
import { Route } from "react-router-dom";

function MatchListEntry(matchId) {
  return (
    <div key={matchId}>
      <a href={"match/" + matchId}>{matchId}</a>
    </div>
  );
}

export function MatchRoutes() {
  return (
    <>
      <Route
        exact
        path="/match"
        render={() => (
          <LoaderWithScreen dataSource={() => api.match.list()}>
            {(props) => props.data.map(MatchListEntry)}
          </LoaderWithScreen>
        )}
      />
      <Route
        exact
        path="/match/:matchId"
        render={(props) => <MatchStreamer matchId={props.match.params.matchId} />}
      />
    </>
  );
}
