import api from "api";
import { Canvas } from "Canvas";
import { EEventType, Match } from "match/match";
import React from "react";
import { Vector2, Matrix3 } from "three";
import Loader from "Loader";
import lafayette from "./maps/lafayette.png";
import lonepine from "./maps/lonepine.png";
import farm from "./maps/farm.png";
import stash from "./maps/stash.png";

const CANVAS = {
  1: { width: 692, height: 1024 },
  2: { width: 1024, height: 580 },
  4: { width: 1024, height: 621 },
  8: { width: 1024, height: 512 },
};

const IMAGE = {
  1: stash,
  2: lafayette,
  4: lonepine,
  8: farm,
};
function OrthoInverse(ortho, canvas) {
  let max = Math.max(canvas.width, canvas.height);
  let scale = max / ortho.width;
  return new Matrix3()
    .translate(canvas.width / 2, canvas.height / 2)
    .multiply(new Matrix3().scale(scale, scale))
    .multiply(new Matrix3().translate(-ortho.x, -ortho.y));
}
const TRANS = {
  // width: SceneCapture Ortho Width, x/y: capture location
  1: OrthoInverse({ width: 52816.0, x: 31737, y: -7399 }, CANVAS[1]),
  2: OrthoInverse({ width: 103443.648438, x: 23040.441406, y: 9771.472656 }, CANVAS[2]),
  4: OrthoInverse({ width: 24888.390625, x: 7551.938965, y: 1988.465698 }, CANVAS[4]),
  8: OrthoInverse({ width: 102400.0, x: 10347.0, y: -77322.257812 }, CANVAS[8]),
};

const SETTINGS = {
  ShotDuration: 0.1,
  ShotLength: 60,
  DamageDuration: 0.1,
  Rates: [1, 2, 4, 10, 50],
};

export class MatchStreamer extends React.Component {
  loaderRef = React.createRef();
  render() {
    return (
      <Loader
        ref={this.loaderRef}
        dataSource={() => api.match.get(this.props.matchId, -1).then((m) => new Match(m))}
      >
        {({ data, loading }) =>
          data && (
            <MatchViewer
              matchId={this.props.matchId}
              match={data}
              loaderRef={this.loaderRef}
              loading={loading}
            />
          )
        }
      </Loader>
    );
  }
}

export class MatchViewer extends React.Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const startTime = parseFloat(urlParams.get("t"));
    // Automatically play unless linked to a specific time.
    this.state = {};
    this.state.done = false;
    if (startTime && startTime > 0) {
      this.state = {
        time: startTime,
        playing: false,
        rate: SETTINGS.Rates.length - 2,
        reverse: false,
      };
    } else {
      this.state = {
        time: 0,
        playing: true,
        rate: SETTINGS.Rates.length - 2,
        reverse: false,
      };
    }
    this.lastTime = startTime;
    this.shots = [];
    this.playerDmg = {};
  }
  componentWillMount() {
    // set up timer
    let tick = () => {
      this.timer = setTimeout(() => {
        if (this.state.playing) {
          let match = this.props.match;
          const newTime =
            this.time() + 0.016 * SETTINGS.Rates[this.state.rate] * (this.state.reverse ? -1 : 1);
          if (newTime < 0 || newTime > match.duration) {
            const clampedTime = Math.min(match.duration, Math.max(0, newTime));
            this.setState({ reverse: false, playing: false, time: clampedTime });
          } else {
            this.time(newTime);
            if (!this.state.done && !this.props.loading && newTime > match.duration - 10) {
              let promise = api.match.get(this.props.matchId, -1); // TODO: could be smarter with time.
              promise = promise.then((m) => {
                let match = new Match(m);
                let done = match.duration <= this.props.match.duration;
                console.log("Done:", done);
                this.setState({ done });
                if (!done && match.duration - 20 > this.time()) {
                  this.time(Math.min(match.duration - 20));
                  this.setState({ rate: 0 });
                }
                return match;
              });
              this.props.loaderRef.current.refreshData(promise);
            }
          }
        }
        tick();
      }, 16);
    };
    tick();
    // If this is called too often the browser will throttle requests
    let urlUpdate = () => {
      this.urltimer = setTimeout(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        urlParams.set("t", this.state.time);
        window.history.replaceState(
          null,
          null,
          window.location.pathname + "?" + urlParams.toString()
        );
        urlUpdate();
      }, 1000);
    };
    urlUpdate();
  }
  componentWillUnmount() {
    // remove timer
    clearTimeout(this.timer);
    clearTimeout(this.urltimer);
  }
  time(time) {
    if (time !== undefined) {
      this.setState({ time });
    } else {
      return this.state.time;
    }
  }
  draw(d, dt) {
    let match = this.props.match;
    let serverType = match.match.Server.ServerType;
    let ctx = d.ctx;
    let players = match.current(this.time(), this.lastTime);
    this.lastTime = this.time();

    d.clear();
    var img = document.getElementById("background");
    d.ctx.drawImage(img, 0, 0);

    let playerDmg = this.playerDmg;
    playerDmg = Object.keys(this.playerDmg).reduce(function (filtered, key) {
      if ((playerDmg[key] -= dt) > 0) filtered[key] = playerDmg[key];
      return filtered;
    }, {});

    Object.keys(players).forEach((p) => {
      let player = players[p];
      let pos = new Vector2(player.P[0], player.P[1]).applyMatrix3(TRANS[serverType]);
      player.Shots.map((yaw) => this.shots.unshift([SETTINGS.ShotDuration, pos, yaw]));
      if (player.EventType === EEventType.Damage) playerDmg[p] = SETTINGS.DamageDuration;

      let color = player.Player ? "#ffc107" : "#fd7e14";
      if (player.EventType === EEventType.Die) color = "#adb5bd";
      if (playerDmg[p]) color = "#dc3545";
      if (player.EventType === EEventType.Exfil) color = "#198754";
      ctx.fillStyle = color;
      ctx.strokeStyle = color;
      d.ctx.font = "20px serif";

      let name = player.Name;
      if (player.Player) d.ctx.fillText(name, pos.x - name.length * 5, pos.y - 10);
      d.circle(pos, 5);
      let facing = new Vector2(20, 0);
      let yaw = (player.R / 180) * Math.PI;
      facing.rotateAround(new Vector2(), (player.R / 180) * Math.PI);
      ctx.globalAlpha = 0.5;
      d.arc(pos, 20, 1.5, yaw);
      ctx.globalAlpha = 1;
    });
    this.shots = this.shots.filter((s) => (s[0] -= dt) > 0);
    this.shots.forEach((s) => {
      ctx.strokeStyle = "orange";
      ctx.globalAlpha = s[0] / SETTINGS.ShotDuration;
      let facing = new Vector2(SETTINGS.ShotLength, 0);
      facing.rotateAround(new Vector2(), (s[2] / 180) * Math.PI);
      d.line(s[1], s[1].clone().add(facing), 2);
      ctx.globalAlpha = 1;
    });
    ctx.strokeStyle = "#fff3";
    d.grid(100);
  }
  render() {
    let { match } = this.props;
    let serverType = match.match.Server.ServerType;
    return (
      <div className="container">
        <img id="background" ref="image" src={IMAGE[serverType]} style={{ display: "none" }} />
        <Canvas {...CANVAS[serverType]} draw={(d, dt) => this.draw(d, dt)} />
        <div className="slidecontainer">
          <button onClick={() => this.time(0)}>&lt;&lt;</button>
          <button
            onClick={() =>
              this.setState({ reverse: true, playing: !this.state.reverse || !this.state.playing })
            }
          >
            {this.state.playing && this.state.reverse ? "=" : "<"}
          </button>
          <button
            onClick={() =>
              this.setState({ reverse: false, playing: this.state.reverse || !this.state.playing })
            }
          >
            {this.state.playing && !this.state.reverse ? "=" : ">"}
          </button>
          <input
            type="range"
            min="0"
            step="any"
            max={"" + match.duration}
            value={"" + this.time()}
            className="slider"
            id="myRange"
            style={{ width: "500px" }}
            onChange={(event) => this.time(parseFloat(event.target.value))}
          />
          <button onClick={() => this.setState({ rate: Math.max(0, this.state.rate - 1) })}>
            -
          </button>
          x{SETTINGS.Rates[this.state.rate]}
          <button
            onClick={() =>
              this.setState({ rate: Math.min(SETTINGS.Rates.length - 1, this.state.rate + 1) })
            }
          >
            +
          </button>
        </div>
      </div>
    );
  }
}
