import api from "api";
import { Droppable } from "dnd";
import React from "react";
import { Button } from "reactstrap";
import { JobMeta, JobOrder } from "settlement/metadata";
import Survivor from "settlement/survivor";
import Stations from "settlement/station";

export class JobVariant extends React.Component {
  render() {
    let jobs = this.props.settlement.jobs[this.props.job] || [];
    let variant = JobMeta[this.props.job[0]].variants[this.props.job[1]];
    return (
      <Droppable id={[this.props.job, this.props.station].flat()} type={"survivor"}>
        {(provided, snapshot) => (
          <div className="job-outer col" ref={provided.innerRef} {...provided.droppableProps}>
            <div className={"job-container" + (snapshot.draggingOver ? " highlight" : "")}>
              <h4>{variant.name}</h4>
              {Object.entries(jobs).map(
                ([survivorId, job], index) =>
                  this.props.settlement.droppedId != survivorId && (
                    <Survivor
                      key={survivorId}
                      id={survivorId}
                      index={index}
                      time={this.props.settlement.time}
                      settlement={this.props.settlement}
                      job={job}
                    />
                  )
              )}
            </div>
          </div>
        )}
      </Droppable>
    );
  }
}

class Job extends React.Component {
  variants() {
    return (JobMeta[this.props.id].variants || [0]).map((t, i) => i);
  }
  render() {
    return (
      <div>
        <h3>{this.props.name}</h3>
        <div className="row">
          {this.variants().map((v) => (
            <JobVariant key={v} job={[this.props.id, v]} settlement={this.props.settlement} />
          ))}
        </div>
      </div>
    );
  }
}

export default class Jobs extends React.Component {
  render() {
    return (
      <div className="jobs">
        {JobOrder.map((id) => (
          <Job key={id} id={id} name={JobMeta[id].name} settlement={this.props.settlement} />
        ))}
        <Stations settlement={this.props.settlement} />
      </div>
    );
  }
}
