import { Vector2 } from "three";

class Draw2D {
  constructor(canvas) {
    this.ctx = canvas.getContext("2d");
  }
  clear() {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    // Scale by DPR **AND** translate by (0.5, 0.5) because canvas is insane
    this.ctx.setTransform(
      devicePixelRatio,
      0,
      0,
      devicePixelRatio,
      0.5 * devicePixelRatio,
      0.5 * devicePixelRatio
    );
  }
  line(a, b, width = 1) {
    // Normally we scale by DPR, but to specify `width` in _device_ pixels
    // we have to scale positions manually because `ctx.lineWidth = 1 / DPR` doesn't work
    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0.5, 0.5);
    this.ctx.lineWidth = width;
    this.ctx.beginPath();
    this.ctx.moveTo(a.x * devicePixelRatio, a.y * devicePixelRatio);
    this.ctx.lineTo(b.x * devicePixelRatio, b.y * devicePixelRatio);
    this.ctx.stroke();
    this.ctx.restore();
  }
  circle(p, radius) {
    this.ctx.beginPath();
    this.ctx.arc(p.x, p.y, radius, 0, 2 * Math.PI);
    this.ctx.fill();
  }
  arc(p, radius, arclength, rotation) {
    this.ctx.beginPath();
    this.ctx.moveTo(p.x, p.y);
    this.ctx.arc(p.x, p.y, radius, rotation - arclength / 2, rotation + arclength / 2, false);
    this.ctx.fill();
  }
  grid(size) {
    let yCount = this.ctx.canvas.height / size;
    for (let i = 0; i < yCount; i++) {
      this.line(
        new Vector2(0, i * size),
        new Vector2(this.ctx.canvas.width / devicePixelRatio - 1, i * size)
      );
    }
    let xCount = this.ctx.canvas.width / size;
    for (let i = 0; i < xCount; i++) {
      this.line(
        new Vector2(i * size, 0),
        new Vector2(i * size, this.ctx.canvas.height / devicePixelRatio - 1)
      );
    }
  }
}

export function Canvas({ draw, width = 401, height = 401 }) {
  let currentCanvas;
  let currentDraw;
  let animationFrameId;
  let lastTime;
  let render = (timestamp) => {
    lastTime = lastTime || timestamp;
    let dt = timestamp - lastTime;
    lastTime = timestamp;
    if (currentCanvas) {
      // Non integer canvas dimensions cause blurring, so add overflow.
      // TODO: Odd dimensions can show a sub-pixel of overflow
      let intWidth = Math.ceil(width * devicePixelRatio) / devicePixelRatio;
      let intHeight = Math.ceil(height * devicePixelRatio) / devicePixelRatio;
      currentCanvas.width = intWidth * devicePixelRatio;
      currentCanvas.height = intHeight * devicePixelRatio;
      currentCanvas.style.width = intWidth + "px";
      currentCanvas.style.height = intHeight + "px";
    }
    // TODO: Gross hack, the Canvas constructor gets called sometimes during match playback so dt ends up as 0
    if (draw) draw(currentDraw, Math.max(16, dt) / 1000.0);
    animationFrameId = window.requestAnimationFrame(render);
  };
  function onRef(canvas) {
    let lastCanvas = currentCanvas;
    if (!canvas) window.cancelAnimationFrame(animationFrameId);
    currentCanvas = canvas;
    currentDraw = canvas && new Draw2D(canvas);
    if (canvas) {
      if (!lastCanvas) animationFrameId = window.requestAnimationFrame(render);
    }
  }

  // Crop the remaining overflow with a container div
  const cropStyle = {
    width: width,
    height: height,
    overflow: "hidden",
  };

  return (
    <div style={cropStyle}>
      <canvas ref={onRef} />
    </div>
  );
}
