import React from "react";
import api from "api";
import Loader from "Loader";
import { DragDropContext } from "dnd";
import "./settlement.scss";
import moment from "moment";
import Jobs from "settlement/job";
import { Storage } from "settlement/storage";
import { v4 as uuid } from "uuid";
import { Button } from "reactstrap";
import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";

let now = () => moment().valueOf() / 1000;

export class SettlementSim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: now(),
      queueAmount: 101,
    };
    this.animFrame = requestAnimationFrame(this.update);
  }
  componentWillUnmount() {
    cancelAnimationFrame(this.animFrame);
  }
  update = async (e) => {
    this.setState({
      time: now(),
    });
    this.animFrame = requestAnimationFrame(this.update);
  };
  render() {
    let queueAmount =
      this.state.queueAmount > 100
        ? -1
        : Math.floor(Math.exp((this.state.queueAmount / 100) * (Math.log(1001) - Math.log(1))) - 1);

    let time = now();
    let loader = this.props.loaderRef.current;
    let childProps = {
      point: {},
      now: time,
      loader,
      settlement: { time: 0, limits: {}, jobs: {}, stations: {}, survivors: {} },
    };
    let onDrop = () => null;
    if (this.props.data) {
      let index = 0;
      let points = this.props.data.Points;
      while (index < points.length - 1) {
        let next = points[index + 1].Time;
        if (time < next) break;
        index++;
      }
      if (index >= points.length - 2) {
        // TODO: Shouldn't call this in render.
        this.props.loaderRef.current.refreshData(null, false);
      }
      let point = points[index];
      childProps.point = point;
      let stations = {};
      for (const [key, type] of Object.entries(this.props.data.Stations || {})) {
        stations[key] = { type, job: null };
      }
      let jobs = {};
      let survivors = {};
      for (const [key, jobKey] of Object.entries(point.Survivors || {})) {
        let job = null;
        let jobType = [null, 0];
        if (jobKey in point.Jobs) {
          job = point.Jobs[jobKey];
          if (job.Station) stations[job.Station].job = jobKey;
          jobType = [job.JobType, job.Variant || 0];
        }
        if (!jobs[jobType]) jobs[jobType] = {};
        jobs[jobType][key] = job;
        survivors[key] = job;
      }
      let stationsByType = {};
      for (const [key, station] of Object.entries(stations)) {
        if (!stationsByType[station.type]) stationsByType[station.type] = [];
        stationsByType[station.type].push({ key, job: station.job });
      }
      childProps.settlement = {
        time: time - point.Time,
        jobs,
        limits: this.props.data.StorageLimit,
        survivors: Object.fromEntries(
          this.props.data.Survivors.map((s) => [s.Record.CharacterGuid, s])
        ),
        stations: stationsByType,
        droppedId: this.props.loading ? this.state.droppedId : undefined,
      };
      let self = this;
      onDrop = function (res) {
        let promise = null;
        let [jobType, variant, stationType] = res.destination.droppableId;
        if (jobType == null) {
          promise = api.settlement.stopJob({ Survivor: res.draggableId });
        } else {
          let station = undefined;
          if (stationType) {
            let job = survivors[res.draggableId];
            let currentStation = job && stations[job.Station];
            if (currentStation && currentStation.type == stationType) {
              // Use our existing station if it exsits, and is the same type.
              station = job.Station;
            } else {
              // Otherwise find an available station.
              station = stationsByType[stationType].find((s) => !s.job);
              station = station && station.key;
            }
          }
          if (stationType && !station) return;
          promise = api.settlement.startJob({
            Type: jobType,
            Variant: variant,
            Station: station,
            Survivor: res.draggableId,
            Repeats: queueAmount,
          });
        }
        // TODO: Could probably predict where this lands.
        self.setState({ droppedId: res.draggableId });
        if (promise) promise.finally(() => self.setState({ droppedId: undefined }));
        loader.refreshData(promise, false);
      };
    }
    return (
      <DragDropContext onDragEnd={(res) => onDrop(res)}>
        <Button
          onClick={() =>
            loader.refreshData(
              api.settlement.addSurvivor({ Record: { CharacterGuid: uuid() } }),
              false
            )
          }
        >
          Add Survivor
        </Button>
        <Button
          onClick={() => loader.refreshData(api.settlement.addContainer(1), false)}
        >
          Container
        </Button>
        <Button
          onClick={() => loader.refreshData(api.settlement.addContainer(2), false)}
        >
          FoodBox
        </Button>
        <Button onClick={() => loader.refreshData(api.settlement.reset(), false)}>
          Reset
        </Button>
        <div class="pull-right">
          <label>Queue Amount: {queueAmount == -1 ? "∞" : queueAmount + 1}</label>
          <input
            type="range"
            min="0"
            max="101"
            value={this.state.queueAmount}
            onChange={(e) => this.setState({ queueAmount: e.target.value })}
          />
        </div>
        <Jobs {...childProps} />
        <Storage {...childProps} />
      </DragDropContext>
    );
  }
}

export class Settlement extends React.Component {
  loaderRef = React.createRef();
  render() {
    return (
      <Loader ref={this.loaderRef} dataSource={() => api.settlement.get()}>
        {({ loading, data, error }) =>
          error ? (
            error.Message
          ) : (
            <SettlementSim loading={loading} data={data} loaderRef={this.loaderRef} />
          )
        }
      </Loader>
    );
  }
}
