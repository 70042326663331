import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { PlayerRoutes } from "routes/Player";
import { MatchRoutes } from "routes/Match";
import { ProfileRoutes } from "./routes/Profile";
import PageWrapper from "PageWrapper";
import "./index.scss";
import { AdminRoutes } from "routes/Admin";
import { HomeRoutes } from "routes/Home";
import { useEffect } from "react";
import 'bootstrap/dist/js/bootstrap.min.js'

function App() {
  useEffect(() => {
    document.title = "XURB";
  }, []);
  return (
    <PageWrapper>
      <BrowserRouter basename="/">
        <Switch>
          <>
            <HomeRoutes />
            <PlayerRoutes />
            <MatchRoutes />
            <ProfileRoutes />
            <AdminRoutes />
          </>
        </Switch>
      </BrowserRouter>
    </PageWrapper>
  );
}

export default App;
