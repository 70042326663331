import React from "react";
import PropTypes from "prop-types";
import { withCookies } from "react-cookie";
import { BASE_URL } from "api";
import sits from "./assets/images/sits_small.png";

const PageContext = React.createContext({});

export const PageProvider = PageContext.Provider;
export const PageConsumer = PageContext.Consumer;
export const PageProp = (Comp) => (props) => {
  return props.page ? (
    <Comp page={props.page} {...props} />
  ) : (
    <PageConsumer>{(page) => <Comp page={page} {...props} />}</PageConsumer>
  );
};

const Signin = PageProp(({ page: { isAuthed } }) => {
  return isAuthed() ? (
    <a href={BASE_URL + "auth/signout"}>Logout</a>
  ) : (
    <a href={BASE_URL + "auth/signin"}>
      <img src={sits} />
    </a>
  );
});

const AUTH_COOKIE = "monolith-auth";
const PRIV_COOKIE = "monolith-priv";
class PageWrapper extends React.Component {
  render() {
    const { cookies, allCookies } = this.props;
    const page = {
      cookies: allCookies,
      isAuthed() {
        return !!allCookies[AUTH_COOKIE];
      },
      isAdmin() {
        return ((allCookies[PRIV_COOKIE] || 0) & 4) !== 0;
      },
      setPrivilege(priv) {
        if (allCookies[PRIV_COOKIE] !== priv) cookies.set(PRIV_COOKIE, priv, { path: "/" });
      },
      logout() {
        this.setPrivilege(0);
        cookies.remove(AUTH_COOKIE, { path: "/" });
      },
    };
    return (
      <PageProvider value={page}>
        <div className="logo-header">
          <nav class="navbar navbar-expand-lg navbar-dark">
            <div className="xurb">XURB</div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mr-auto mt-lg-0">
                <li className="nav-item active">
                  <a href="/">Home</a>
                </li>
                <li className={`nav-item`}>
                  <a href="/player">Profile</a>
                </li>
                {page.isAdmin() ? (
                  <li className="nav-item">
                    <a href="/admin">Admin</a>
                  </li>
                ) : null}
                <li className="nav-item">
                  <Signin />
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container margin-border">{this.props.children}</div>
      </PageProvider>
    );
  }
}

PageWrapper.propTypes = {};

export default withCookies(PageWrapper);
