import { Button } from "reactstrap";
import { PageConsumer } from "PageWrapper";
import React from "react";
import api from "api";

function StorageLimit({ name, limit, loader }) {
  let limitRef = React.createRef();
  return (
    <input
      type="text"
      ref={limitRef}
      className="stealth"
      onBlur={() => (limitRef.current.value = "")}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          let clear = !limitRef.current.value;
          loader.refreshData(
            api.settlement.setLimit(name, clear ? -1 : Number(limitRef.current.value)),
            false
          );
        }
      }}
    />
  );
}

class StorageEntry extends React.Component {
  value() {
    return Math.max(
      0,
      Math.min(this.props.storage + this.props.settlement.time * this.props.production)
    );
  }
  render() {
    let production = Math.round(this.props.production * 10) / 10;
    let value = Math.round(this.value() * 10) / 10;
    let limit = Math.round(this.props.limit);
    let deleteX = (x) => () =>
      this.props.loader.refreshData(
        api.settlement.delete({ Item: this.props.name, Amount: x }),
        false
      );
    let exchangeX = (x) => () =>
      this.props.loader.refreshData(
        api.settlement.exchange({ Item: this.props.name, Amount: x }),
        false
      );
    return (
      <div className="row showParent">
        <div className="col pull-right">
          {this.props.name}: {value}
          {limit >= 0 ? "/" + limit : ""}({production >= 0 ? "+" : ""}
          {production})
        </div>
        <PageConsumer>
          {(page) => (
            <div className="col">
              <div className="showChild">
                /
                <StorageLimit
                  name={this.props.name}
                  loader={this.props.loader}
                  limit={this.props.limit}
                />
                {page.isAdmin() && (
                  <>
                    &nbsp;
                    <Button className="stealth" onClick={exchangeX(1)}>
                      &nbsp;&nbsp;&nbsp;&nbsp;+1
                    </Button>
                    &nbsp;
                    <Button className="stealth" onClick={exchangeX(10)}>
                      &nbsp;&nbsp;&nbsp;+10
                    </Button>
                    &nbsp;
                    <Button className="stealth" onClick={exchangeX(100)}>
                      &nbsp;+100
                    </Button>
                  </>
                )}
                &nbsp;
                <Button className="stealth" onClick={deleteX(1)}>
                  &nbsp;&nbsp;&nbsp;&nbsp;-1
                </Button>
                &nbsp;
                <Button className="stealth" onClick={deleteX(10)}>
                  &nbsp;&nbsp;&nbsp;-10
                </Button>
                &nbsp;
                <Button className="stealth" onClick={deleteX(100)}>
                  &nbsp;-100
                </Button>
                &nbsp;
                <Button className="stealth" onClick={deleteX(this.props.storage)}>
                  &nbsp;&nbsp;&nbsp;&nbsp;X
                </Button>
              </div>
            </div>
          )}
        </PageConsumer>
      </div>
    );
  }
}

export class Storage extends React.Component {
  storageProps(key) {
    let point = this.props.point;
    let limit = this.props.settlement.limits[key];
    return {
      name: key,
      settlement: this.props.settlement,
      storage: point.Storage[key] || 0,
      production: 0,
      limit: limit === undefined ? -1 : limit,
      loader: this.props.loader,
    };
  }
  render() {
    let capacity = Object.values(this.props.point.Containers || {}).reduce(
      (p, c) => p + c.Capacity,
      0
    );
    return (
      <div className="storage">
        Storage
        <Button onClick={() => this.props.loader.refreshData(api.settlement.clearStorage(), false)}>
          Clear
        </Button>
        <div>
          Storage Capacity: {capacity}
          {Object.entries(this.props.point.Storage || {}).map(([key, value]) => (
            <StorageEntry key={key} {...this.storageProps(key)} />
          ))}
        </div>
      </div>
    );
  }
}
