export class Inventory {
  constructor(inventory) {
    inventory.Slots.forEach((slot) => this.Add(slot));
  }
  slots = {};
  Add(slot) {
    var tags = [...slot.TagString.matchAll(/\(TagName="Inventory\.Slot\.(.+?)"\)/gm)].map(
      (t) => t[1]
    );
    var slotEntry = { tags: new Set(tags), item: slot.Item };
    tags.forEach((tag) => {
      if (!(tag in this.slots)) this.slots[tag] = [];
      this.slots[tag][slot.TagIndex] = slotEntry;
    });
  }
  Get(tag, index = 0) {
    return (
      (tag in this.slots && this.slots[tag] && this.slots[tag][index]) || {
        item: null,
        tags: new Set([tag]),
      }
    );
  }
}
