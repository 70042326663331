import React from "react";
import api from "api";
import { Route } from "react-router-dom";
import Loader, { LoaderWithScreen } from "Loader";
import moment from "moment";
import clear_day from "assets/images/weather/clear_day.png";
import clear_night from "assets/images/weather/clear_night.png";
import cloudy from "assets/images/weather/cloudy.png";
import foggy from "assets/images/weather/foggy.png";
import lightRain from "assets/images/weather/lightRain.png";
import overcast from "assets/images/weather/overcast.png";
import partlyCloudy_day from "assets/images/weather/partlyCloudy_day.png";
import partlyCloudy_night from "assets/images/weather/partlyCloudy_night.png";
import rain from "assets/images/weather/rain.png";
import snow from "assets/images/weather/snow.png";
import thunderstorm from "assets/images/weather/thunderstorm.png";
import { Settlement } from "settlement/settlement";

const weatherName = {
  foggy: "Foggy",
  overcast: "Overcast",
  partlyCloudy: "Partly Cloudy",
  cloudy: "Cloudy",
  clear: "Clear Skies",
  lightSnow: "Light Snow",
  lightRain: "Light Rain",
  snow: "Snow",
  rain: "Rain",
  blizzard: "Blizzard",
  thunderstorm: "Thunderstorm",
};
const weatherImage = {
  foggy: [foggy, foggy],
  overcast: [overcast, overcast],
  partlyCloudy: [partlyCloudy_day, partlyCloudy_night],
  cloudy: [cloudy, cloudy],
  clear: [clear_day, clear_night],
  lightSnow: [snow, snow],
  lightRain: [lightRain, lightRain],
  snow: [snow, snow],
  rain: [rain, rain],
  blizzard: [snow, snow],
  thunderstorm: [thunderstorm, thunderstorm],
};

function GetWeather(weather) {
  if (weather.Pr < 0.2) {
    if (weather.Cl < 0.2) return "clear";
    if (weather.Cl < 4) return "cloudy";
    if (weather.Cl < 6.2) return "partlyCloudy";
    if (weather.Cl < 8.5) return "overcast";
    return "foggy";
  } else if (weather.Pr < 4.5) {
    return weather.RS < 5 ? "lightRain" : "lightSnow";
  } else if (weather.Pr < 8) {
    return weather.RS < 5 ? "rain" : "snow";
  }
  return weather.RS < 5 ? "thunderstorm" : "blizzard";
}

function WeatherEntry(weather) {
  let gameTime = moment.unix(weather.t0 + weather.timeDelta);
  let duration = moment.duration((weather.timeDelta / weather.timeRate) * 1000);
  let hour = gameTime.hour();
  let day = hour > 5 && hour < 20;
  let weatherType = GetWeather(weather);
  return (
    <div className="col">
      <center>
        <h3>{gameTime.format("LTS")}</h3>
        <div>(+{duration.asMinutes()}min)</div>
        <img alt="" src={weatherImage[weatherType][day ? 0 : 1]} style={{ width: "64px" }} />
        <h3>{weatherName[weatherType]}</h3>
      </center>
    </div>
  );
}

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.config = this.props.WorldConfig;
    this.state = { time: 0 };
  }
  componentWillMount() {
    // set up timer
    let tick = () => {
      this.timer = setTimeout(() => {
        this.setState({ time: this.state.time + 1 });
        tick();
      }, 1000 / this.config.TimeRate);
    };
    tick();
  }
  render() {
    let { StartTime, TimeRate, ForecastPeriod, Forecast } = this.config;
    let time = moment.unix(StartTime + this.state.time);
    return (
      <>
        <h1>Date</h1>
        <h3>
          {time.format("L")} {time.format("LT")}
        </h3>
        <h1>Weather</h1>
        <div className="row">
          {Forecast.map((forecast, i) =>
            i % 6 === 0 ? (
              <WeatherEntry
                key={i}
                t0={StartTime}
                timeDelta={i * ForecastPeriod}
                timeRate={TimeRate}
                {...forecast}
              />
            ) : null
          )}
        </div>
      </>
    );
  }
}

export class HomeRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Route exact path="/">
          <LoaderWithScreen dataSource={() => api.server.getConfig()}>
            {({ data, error }) => (data && <HomePage {...data} />) || "Error: " + error.Message}
          </LoaderWithScreen>
          <Settlement />
        </Route>
      </>
    );
  }
}
