export const BASE_URL = process.env.REACT_APP_BASE_URL || "/api/";

function call(endpoint, queryParams) {
  let options = {
    method: "POST",
    headers: {},
  };
  if (queryParams) {
    if (queryParams.__proto__ !== FormData.prototype) {
      options.headers["Content-Type"] = "application/json";
      queryParams = JSON.stringify(queryParams);
    }
    options.body = queryParams;
  }

  return fetch(BASE_URL + endpoint, options).then(async (r) => {
    if (!r.ok) {
      let error = {};
      try {
        error = await r.json();
      } catch {
        error.Message = r.statusText;
      }
      error.Status = r.status;
      throw error;
    }
    return await r.json();
  });
}

export function withAuth(page, promise) {
  return promise.catch((r) => {
    if (r.Status === 400 || r.Status === 401) {
      console.log("Logged out");
      page.logout();
    }
    throw r;
  });
}

var api = {
  player: {
    getSelf: () => call("player/getself"),
    get: (id) => call("player/get", id),
    list: () => call("player/list"),
    listsessions: (id) => call("player/listsessions", { PlayerId: id }),
  },
  match: {
    get: (id, time) => call("match/get", { MatchId: id, Time: time }),
    list: () => call("match/list"),
  },
  server: {
    refresh: () => call("server/refresh"),
    getConfig: () => call("server/getconfig"),
    setConfig: (key, value) => call("server/setconfig", { Key: key, Value: value }),
    setPlayPrivilege: (value) => call("server/setplayprivilege", value),
    deleteConfig: (key) => call("server/deleteconfig", key),
    notify: (message) => call("server/notify", message),
    removeserver: (matchId) => call("server/removeserver", matchId),
    setwarmpool: (size) => call("server/setwarmpool", size),
    setNowOffset: (offset) => call("server/setnowoffset", offset),
  },
  loot: {
    get: (type) => call("loot/get", type),
    updateOverride: (type, override) =>
      call("loot/updateoverride", { Type: type, Override: override }),
  },
  settlement: {
    get: () => call("settlement/getsettlement"),
    reset: () => call("settlement/reset"),
    setLimit: (item, amount) => call("settlement/setlimit", { Item: item, Amount: amount }),
    clearStorage: () => call("settlement/clearStorage"),
    addSurvivor: (req) => call("settlement/addSurvivor", req),
    addContainer: (req) => call("settlement/addContainer", req),
    addStation: (req) => call("settlement/addStation", req),
    startJob: (req) => call("settlement/startJob", req),
    stopJob: (req) => call("settlement/stopJob", req),
    delete: (req) => call("settlement/delete", req),
    exchange: async (req) => (await call("settlement/exchange", req)).Timeline,
  },
};

export default api;
