import React, { useState } from "react";
import api from "api";
import { Route } from "react-router-dom";
import Loader, { LoaderWithScreen } from "Loader";
import { serverType } from "enums";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import LootTable from "loot/lootTable";

function ServerEntry({ server, refreshData }) {
  return (
    <div>
      {serverType(server.ServerType) + ": " + server.MatchId}&nbsp;
      <a href={"/match/" + server.MatchId}>Watch</a>
      <button
        onClick={async () => {
          await api.server.removeserver(server.MatchId);
          refreshData(false, false);
        }}
      >
        Remove
      </button>
      <ul>
        {server.DbPlayers.map((p) => (
          <li key={p.Guid}>{p.Name}</li>
        ))}
      </ul>
    </div>
  );
}

function PlayPrivilegeDropDown({ playPrivilege, refreshData }) {
  const pName = (p) => ["None", "Playtest", "Cheats", "Playtest+Cheats", "Admin"][p];
  function Item({ priv }) {
    return (
      <DropdownItem
        onClick={async () => {
          await api.server.setPlayPrivilege(priv);
          refreshData(false, false);
        }}
      >
        {pName(priv)}
      </DropdownItem>
    );
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret size="md">
        {pName(playPrivilege)}
      </DropdownToggle>
      <DropdownMenu>
        <Item priv={0} />
        <Item priv={1} />
        <Item priv={2} />
        <Item priv={4} />
      </DropdownMenu>
    </Dropdown>
  );
}

export class AdminRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { configData: {} };
  }
  render() {
    return (
      <Route exact path="/admin">
        <h2>Servers</h2>
        <div className="row">
          <LoaderWithScreen dataSource={() => api.server.refresh()}>
            {({ data, error, refreshData }) => {
              return (
                (data &&
                  data.map((s) => (
                    <ServerEntry key={s.MatchId} server={s} refreshData={refreshData} />
                  ))) ||
                error.Message
              );
            }}
          </LoaderWithScreen>
        </div>
        <h2>Config</h2>
        <LoaderWithScreen dataSource={() => api.server.getConfig()}>
          {({ data, error, refreshData }) => {
            if (error) return error.Message;
            if (!data) return "Empty config response.";
            return (
              <>
                <div className="row">
                  <div className="col">Config: {JSON.stringify(data.Config)}</div>
                </div>
                <div className="row">
                  <label className="col">Config Key</label>
                  <input
                    className="col"
                    type="text"
                    value={this.state.configKey}
                    onChange={(e) => this.setState({ configKey: e.target.value })}
                    name="name"
                  />
                  <button
                    className="col"
                    onClick={async () => {
                      await api.server.deleteConfig(this.state.configKey);
                      refreshData(false, false);
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="row">
                  <label className="col">Config Value</label>
                  <input
                    className="col"
                    type="text"
                    value={this.state.configValue}
                    onChange={(e) => this.setState({ configValue: e.target.value })}
                    name="name"
                  />
                  <button
                    className="col"
                    onClick={async () => {
                      let value = this.state.configValue;
                      try {
                        value = JSON.parse(value);
                      } catch (e) {}
                      await api.server.setConfig(this.state.configKey, value);
                      refreshData(false, false);
                    }}
                  >
                    Set
                  </button>
                </div>
                <div className="row">
                  <label className="col">Set now offset (hours)</label>
                  <input
                    className="col"
                    type="text"
                    value={this.state.nowOffset}
                    onChange={(e) => this.setState({ nowOffset: e.target.value })}
                    name="name"
                  />
                  <button
                    className="col"
                    onClick={() =>
                      api.server.setNowOffset(Number.parseInt(this.state.nowOffset * 3600))
                    }
                  >
                    Send
                  </button>
                </div>
                <h2>Control</h2>
                Server Config: {JSON.stringify(data.ServerConfig)}
                <div className="row">
                  <label className="col">Send notification</label>
                  <input
                    className="col"
                    type="text"
                    value={this.state.notification}
                    onChange={(e) => this.setState({ notification: e.target.value })}
                    name="name"
                  />
                  <button
                    className="col"
                    onClick={() => api.server.notify(this.state.notification)}
                  >
                    Send
                  </button>
                </div>
                <div className="row">
                  <label className="col">Set warm pool size</label>
                  <input
                    className="col"
                    type="text"
                    value={this.state.warmPool}
                    onChange={(e) => this.setState({ warmPool: e.target.value })}
                    name="name"
                  />
                  <button
                    className="col"
                    onClick={() => api.server.setwarmpool(Number.parseInt(this.state.warmPool))}
                  >
                    Send
                  </button>
                </div>
                <label>Play Privilege:</label>
                <PlayPrivilegeDropDown
                  playPrivilege={data.PlayPrivilege}
                  refreshData={refreshData}
                />
                <h2>Info</h2>
                Idle Duration: {data.IdleDuration}
              </>
            );
          }}
        </LoaderWithScreen>
        <LootTable />
      </Route>
    );
  }
}
