import React from "react";

export default class Loader extends React.Component {
  mounted = true;
  refreshPending = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: !!this.props.dataSource,
      data: null,
      error: null,
    };
  }
  componentDidUpdate = async (prevProps) => {
    if (prevProps.refreshId != this.props.refreshId) {
      await this.refreshData();
    }
  };
  componentDidMount = async () => {
    await this.refreshData();
  };

  componentWillUnmount = () => (this.mounted = false);

  refreshData = async (promise, showLoader = true) => {
    if (this.refreshPending) return;
    this.refreshPending = true;
    this.setState({ isLoading: showLoader });
    let newData = null;
    let newError = null;
    await (promise || this.props.dataSource())
      .then((data) => (newData = data))
      .catch((error) => (newError = error));
    this.refreshPending = false;
    const delay = (millis) =>
      new Promise((resolve, reject) => {
        setTimeout((_) => resolve(), millis);
      });
    await delay(500);
    await new Promise((resolve) =>
      this.setState({ isLoading: false, data: newData, error: newError }, resolve)
    );
  };

  render() {
    return (
      <>
        {this.props.children({
          loading: this.state.isLoading,
          data: this.state.data,
          error: this.state.error,
          refreshData: this.refreshData,
        })}
      </>
    );
  }
}

export class LoaderWithScreen extends React.Component {
  render() {
    return (
      <Loader dataSource={this.props.dataSource}>
        {(props) => {
          if (props.loading) return <>Loading</>;
          return this.props.children({
            ...props,
          });
        }}
      </Loader>
    );
  }
}
