import React from "react";
import { JobVariant } from "settlement/job";
import { JobMeta, StationMeta, StationOrder } from "settlement/metadata";

export default class Stations extends React.Component {
  render() {
    return StationOrder.map((id) => (
      <Station key={id} id={id} name={StationMeta[id].name} settlement={this.props.settlement} />
    ));
  }
}

class Station extends React.Component {
  jobs() {
    return StationMeta[this.props.id].jobs
      .map((j) => JobMeta[j].variants.map((t, i) => [j, i]))
      .flat();
  }
  render() {
    let stations = this.props.settlement.stations[this.props.id] || [];
    let inUse = stations.map((s) => (s.job ? 1 : 0)).reduce((a, b) => a + b, 0);
    return (
      <div>
        <h3>
          {this.props.name} ({inUse}/{stations.length})
        </h3>
        <div className="row">
          {this.jobs().map((job) => (
            <JobVariant
              key={job}
              job={job}
              station={this.props.id}
              settlement={this.props.settlement}
            />
          ))}
        </div>
      </div>
    );
  }
}
