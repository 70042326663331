import api, { withAuth } from "api";
import { Route } from "react-router-dom";
import { PlayerInventory, GridInventory, Inventory } from "inventory";
import Loader, { LoaderWithScreen } from "Loader";
import { PageProp } from "PageWrapper";
import { fromUnix } from "time";
import { serverType } from "enums";

function PlayerListEntry(player) {
  return (
    <div key={player.Id}>
      <a href={"player/" + player.Guid}>{player.Guid}</a>
    </div>
  );
}

function PlayerEntry(props) {
  const { player, sessions } = props;
  return (
    <div key={player.Id}>
      <h1>{player.Name}</h1>
      <h2>Sessions</h2>
      {sessions.map((session) => (
        <div className="row" key={session.MatchId}>
          <a href={`/match/${session.MatchId}`}>
            <div className="d-inline-flex">
              <b>{serverType(session.ServerType) + " " + session.MatchId.substr(-4)}</b>:{" "}
              {fromUnix(session.StartTime).fromNow()}
            </div>
          </a>
        </div>
      ))}
      <div className="row wrap">
        <PlayerInventory inventory={new Inventory(player.Root.Inventory)} />
        {player.Root.Stash["hub_stash"] && (
          <GridInventory dims={[8, 20]} inventory={new Inventory(player.Root.Stash["hub_stash"])} />
        )}
      </div>
    </div>
  );
}

// TODO: Should just include sessions in get self route
let dataSource = (playerPromise) => async () => {
  var player = await playerPromise();
  var sessions = await api.player.listsessions(player.Guid);
  return { player: player, sessions: sessions };
};
function PlayerPage({ page }) {
  let ds = dataSource(
    () => withAuth(page, api.player.getSelf()).then((data) => {
      page.setPrivilege(data.Privilege);
      return data;
    })
  );
  return (
    <LoaderWithScreen dataSource={ds}>
      {({ data, error }) => (data && <PlayerEntry {...data} />) || "Error: " + error.Message}
    </LoaderWithScreen>
  );
}

export function PlayerRoutes() {
  return (
    <>
      <Route exact path="/player" render={PageProp(PlayerPage)} />
      <Route
        exact
        path="/players"
        render={() => (
          <>
            <LoaderWithScreen dataSource={api.player.list}>
              {(props) => props.data.map((player) => PlayerListEntry(player))}
            </LoaderWithScreen>
          </>
        )}
      />
      <Route
        exact
        path="/player/:playerId"
        render={(props) => (
          <LoaderWithScreen dataSource={dataSource(() => api.player.get(props.match.params.playerId))}>
            {({ data }) => (
              <>
                <PlayerEntry {...data} />
              </>
            )}
          </LoaderWithScreen>
        )}
      />
    </>
  );
}
