export const JobOrder = [null, 2, 8];
export const JobMeta = {
  null: {
    name: "Idle",
    variants: [{}],
  },
  0: {
    name: "Farm",
    station: 0,
    variants: [
      { name: "Carrot", duration: 10 },
      { name: "Potato", duration: 20 },
      { name: "Flax", duration: 20 },
    ],
  },
  2: {
    name: "Gather Seeds",
    variants: [{ duration: 10 }],
  },
  7: {
    name: "Cook",
    station: 1,
    variants: [{ name: "Snack", duration: 5 }, { name: "Meal", duration: 5  }, { name: "Hearty Meal", duration: 5  }],
  },
  8: {
    name: "Lumber",
    variants: [{duration: 1}],
  },
  10: {
    name: "Manual Mill",
    variants: [{ name: "2x4", duration: 10 }, { name: "Plywood", duration: 25 }],
  },
  11: {
    name: "Spin Cloth",
    variants: [{ name: "Flax", duration: 5 }],
  },
};

export const StationOrder = [1, 2, 3];
export const StationMeta = {
  1: {
    name: "Farm Plot",
    jobs: [0],
  },
  2: {
    name: "Kitchen",
    jobs: [7],
  },
  3: {
    name: "Spinning Wheel",
    jobs: [11],
  },
};
