import { Draggable } from "dnd";
import React from "react";
import { JobMeta } from "settlement/metadata";

export default class Survivor extends React.Component {
  getProps(provided) {
    let result = {
      ref: provided.innerRef,
      ...provided.draggableProps,
      ...provided.dragHandleProps,
    };
    return result;
  }
  render() {
    let job = this.props.job;
    let progressStyle = {};
    let duration = job && JobMeta[job.JobType].variants[job.Variant].duration;
    if (duration) {
      let fraction = Math.min(
        100,
        ((job.RunTime + (job.StartTime ? this.props.time * 1 : 0)) /
          JobMeta[job.JobType].variants[job.Variant].duration) *
          100
      );
      progressStyle = {
        background: `linear-gradient(to right, green ${fraction}%, ${
          job.StartTime ? "transparent" : "red"
        } ${fraction}%)`,
      };
    }
    return (
      <Draggable type="survivor" id={this.props.id} index={this.props.index}>
        {(provided, snapshot) => (
          <div className="survivor" {...this.getProps(provided)}>
            {job && (
              <div className="counter">
                {job.RepeatsRemaining == -1 ? "∞" : (job.RepeatsRemaining + 1)}
              </div>
            )}
            <div className="progressbar" style={progressStyle}>
              <div>{this.props.settlement.survivors[this.props.id].Name}</div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
