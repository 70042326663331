// TODO: Load this or something
export default [
  "WaterBottle",
  "Soda_Item",
  "Item_12g_00_Buckshot",
  "Item_12g_Slug",
  "Item_308",
  "Item_45",
  "Item_45_LR",
  "Item_556x45",
  "Item_556x45_AP",
  "Item_762x39",
  "Item_762x39_LR",
  "Item_762x51",
  "Item_762x51_LR",
  "Item_9x19",
  "Item_9x19_LR",
  "AK47_Magazine",
  "AngledGrip_Item",
  "Compensator",
  "Item_R700_Magazine",
  "Item_R700_Scope",
  "LaserSight06",
  "M14_Magazine",
  "MP5_Magazine_Item",
  "MuzzleBrake",
  "P320_Magazine_Item",
  "RedDot",
  "Scope4x",
  "Stanag_Magazine",
  "Stanag_Magazine_Extended",
  "Suppressor",
  "UMP45_Magazine",
  "VerticalGrip_Item",
  "WeaponFlashlight",
  "Cigarettes",
  "Soap",
  "ToiletPaper_Item",
  "Comic1",
  "DuffleBag",
  "MedicalContainer",
  "SmallAmmoContainer",
  "WeaponContainer",
  "Figure_1",
  "Figure_2",
  "Figure_3",
  "Figure_4",
  "Figure_5",
  "Apple1_Item",
  "Apple2_Item",
  "Banana_Item",
  "Cereal_Item",
  "Melon_Item",
  "PeanutButter_Item",
  "Watermelon_Item",
  "Beret",
  "BlueJeans",
  "CamoBoots",
  "CamoJacket",
  "CamoPants",
  "CargoPants",
  "CombatBoots",
  "CombatGloves",
  "FaceMask",
  "FannyPack",
  "FullHelmet",
  "G4Backpack",
  "GearPantsBase",
  "GearShirtBase",
  "HikingBackpack",
  "Hoodie",
  "LongSleeve",
  "MagazinePouch",
  "MegaBackpack",
  "MilitaryFullVest",
  "MilitaryHeadset",
  "MilitaryHeavyVest",
  "MilitaryVest",
  "NVG",
  "PocketPouch",
  "Scarf",
  "SchoolBackpack",
  "SquareBackpack",
  "SuitPants",
  "SuitShirt",
  "SuitShoes",
  "TacticalHelmet",
  "TacticalLongsleeve",
  "TShirtBlue",
  "100Key",
  "101Key",
  "104Key",
  "105Key",
  "108Key",
  "109RoomKey",
  "112RoomKey",
  "113RoomKey",
  "116Key",
  "120Key",
  "BunkerGarageKey",
  "CarboyGarageKey",
  "Item_Key2",
  "Item_Key3",
  "Item_PriestKey",
  "OfficeTrailer_Key",
  "SchoolOfficeKey",
  "TutKey",
  "Generator",
  "PlasticDrum",
  "2x4_Medium_Item",
  "Cloth_Item",
  "Hose_Item",
  "Nails_Item",
  "Pipe_Item",
  "Plywood_Item",
  "SheetMetal_Item",
  "SparkPlug_Item",
  "Bandage_Item",
  "Medkit_Small_Item",
  "Morphine_Item",
  "PainKillers_Item",
  "SplintKit_Item",
  "Item_BlueCard",
  "Item_GreenCard",
  "Item_IdCard",
  "Item_Note",
  "Item_PinkCard",
  "Money_Item",
  "BoltCutters_Item",
  "DuctTape_Item",
  "ElectricalTape_Item",
  "Explosive_Item",
  "Flashlight_Item",
  "GoldenHammer_Item",
  "Hammer_Item",
  "Lubricant_Item",
  "Pliers_Item",
  "RepairKit_Item",
  "Saw_Item",
  "Scissors_Item",
  "ScrewDriver_Item",
  "Shovel_Item",
  "SprayPaint_Item",
  "TapeMeasure_Item",
  "Wrench_Item",
  "AK47",
  "M14",
  "MP5_Item",
  "P320_Item",
  "R700_Item",
  "R870_Item",
  "Scar",
  "UMP45",
];
