import React from "react";
import { Route } from "react-router-dom";
import { Collapse } from "reactstrap";

class ProfileNode {
  constructor(key, row) {
    this.key = key;
    const re = /\s*((\d+\.\d+)ms.+?-)?\s*(.+?)\s*(-\s*(.+?))?$/gm;
    let matches = re.exec(row.text);
    this.depth = row.depth;
    this.children = [];
    this.totalDuration = this.duration = Number.parseFloat(matches[2]);
    this.nameId = matches[3];
    this.name = this.nameId.split("/")[0];
    this.desc = matches[4];
  }
  getTotalDuration() {
    let t = this.duration || 0;
    this.children.forEach((child) => {
      t += child.getTotalDuration();
    });
    return t;
  }
}
export function ProfileNodeEntry({ node, inIsOpen = false, flat = {} }) {
  const [isOpen, setIsOpen] = React.useState(inIsOpen);
  return node && (
    <li>
      <div className="row" onClick={() => setIsOpen(!isOpen)}>
        {node.duration.toFixed(2)} ({node.getTotalDuration().toFixed(2)}) / 
        {(flat[node.name] || 0).toFixed(2)} {node.nameId}
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <ul>
            {node.children.map((c, i) =>
              c.duration > 0 ? <ProfileNodeEntry key={i} node={c} flat={flat} /> : null
            )}
          </ul>
        </Collapse>
      </div>
    </li>
  );
}

export default class ProfileTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addr: "http://localhost:4000/profile", profileText: "", root: null };
  }
  parseTree(rows) {
    let roots = [];
    let flat = {};
    rows.nodes.forEach((row, i) => {
      let nextNode = new ProfileNode(i, row);
      if (nextNode.depth < 0) return;
      if (roots.length === 0) {
        roots.push(nextNode);
        return;
      }
      while (roots.length > nextNode.depth) roots.pop();
      if (nextNode.name === "CPU Stall") {
        roots.forEach((node) => (node.duration -= nextNode.duration));
        return;
      }
      roots.at(-1).children.push(nextNode);
      roots.push(nextNode);
    });
    let traverse = [roots[0]];
    while (traverse.length > 0) {
      let cur = traverse.pop();
      flat[cur.name] = (flat[cur.name] || 0) + cur.duration;
      cur.children.forEach((c) => traverse.push(c));
    }
    return { root: roots[0], flat };
  }

  async remoteProfile(event) {
    event.preventDefault();
    try {
      let res = await fetch(this.state.addr, { method: "GET" });
      let json = await res.json();
      this.setState({ ...this.parseTree(json) });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    let { root, flat = {} } = this.state;
    return (
      <>
        <form onSubmit={(event) => this.remoteProfile(event)}>
          <label>
            Address:
            <input
              type="text"
              value={this.state.addr}
              onChange={(event) => this.setState({ addr: event.target.value })}
            />
          </label>
          <input type="submit" value="Profile" />
        </form>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            this.setState({ ...this.parseTree(JSON.parse(this.state.profileText)) });
          }}
        >
          <label>
            Raw Profile Text:
            <textarea
              value={this.state.profileText}
              onChange={(event) => this.setState({ profileText: event.target.value })}
            />
          </label>
          <input type="submit" value="Parse" />
        </form>
        {root && <ProfileNodeEntry inIsOpen node={root} flat={flat} />}
      </>
    );
  }
}

export function ProfileRoutes() {
  return (
    <>
      <Route exact path="/profile" render={() => <ProfileTree />} />
    </>
  );
}
