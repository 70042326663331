import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

function AutoCompleteOption({ value, setValue }) {
  return (
    <button
      type="button"
      tabIndex="0"
      role="menuitem"
      class="dropdown-item"
      onMouseDown={(e) => {
        e.stopPropagation();
        setValue(value);
      }}
    >
      {value}
    </button>
  );
}

const AutoComplete = ({ value, options = [], onValue = () => {} }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  // TODO: This is janky, but you can't get state callbacks without using a class I guess???
  const [submitting, setSubmitting] = useState(false);
  const inputRef = useRef();
  options = options.filter((option) => option?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1);
  useEffect(() => {
    if (submitting) {
      setSubmitting(false);
      inputRef.current.form.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
    }
  });
  function finalizeValue(value) {
    setMenuVisible(false);
    inputRef.current.blur();
    setSubmitting(true);
    onValue(value);
  }
  return (
    <div
      class="dropdown"
      onBlur={() => {
        setMenuVisible(false);
      }}
    >
      <input
        type="text"
        ref={inputRef}
        onFocus={() => {
          setMenuVisible(true);
        }}
        onChange={(e) => onValue(e.target.value)}
        // TODO: Hacky BS because onblur doesn't work right on react.
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            if (options.length > 0) {
              finalizeValue(options[0]);
            }
          }
        }}
        value={value}
      />
      <div class={"dropdown-menu" + (menuVisible ? " show" : "")}>
        {options.map((d, i) => (
          <AutoCompleteOption key={i} value={d} setValue={finalizeValue} />
        ))}
      </div>
    </div>
  );
};

export default AutoComplete;
