export const EEventType = {
  Position: 0,
  Damage: 1,
  Shoot: 2,
  Die: 3,
  Exfil: 4,
};

function P(frame) {
  return [frame.X, frame.Y, frame.Z];
}

export class Match {
  constructor(match) {
    this.match = match;
    this.start = match.StartTime;
    this.duration = 0;
    this.characterFrames = {};
    this.characters = { ...match.Players, ...match.NPCs };
    Object.keys(this.characters).forEach((p) => {
      this.characterFrames[p] = 0;
      let events = this.characters[p].Events;
      this.duration = Math.max(this.duration, ...events.map((p) => p.Time));
    });
  }
  current(time, lastTime) {
    return Object.keys(this.characterFrames).reduce((obj, p) => {
      const playerSession = this.characters[p];
      let events = playerSession.Events;
      if (events.length === 0) return obj;
      this.characterFrames[p] = Math.max(...events.map((p, i) => (p.Time <= time ? i : 0)));
      if (this.characterFrames[p] === -1) return obj;
      obj[p] = {
        Name:
          (playerSession.Player && playerSession.Player.Name) || playerSession.Name || p.substr(-5),
        ...this.interpolate(p, time, lastTime),
      };
      return obj;
    }, {});
  }
  interpolate(player, time, lastTime) {
    let events = this.characters[player].Events;
    let index = this.characterFrames[player];
    const frame1 = events[index];
    const frame2 = events[index + 1] || frame1;

    let shots = [];
    let damage = false;
    while (index > 0 && events[index].Time >= lastTime) {
      if (events[index].EventType === EEventType.Shoot) {
        shots.push(events[index].Yaw);
      }
      if (events[index].EventType === EEventType.Damage) {
        damage = true;
      }
      index--;
    }
    const w = frame2.Time === frame1.Time ? 0 : (time - frame1.Time) / (frame2.Time - frame1.Time);
    return {
      ...frame1,
      P: this.lerp3(P(frame1), P(frame2), w),
      R: this.lerpR(frame1.Yaw, frame2.Yaw, w),
      Shots: shots,
      Damage: damage,
      Player: this.characters[player].Player || false,
    };
  }
  lerpR(a, b, w) {
    if (a - b > 180) b += 360;
    if (b - a > 180) a += 360;
    return a * (1 - w) + b * w;
  }
  lerp3(a, b, w) {
    let result = a.map((va, i) => va * (1 - w) + b[i] * w);
    return result;
  }
}
