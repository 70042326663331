const Items = {
  "/Game/Untitled/Objects/ItemDefinitions/Weapons/BP_AK47.BP_AK47_C": {
    dims: [4, 2],
    name: "AK47",
  },
  "/Game/Untitled/Objects/ItemDefinitions/Ammo/BP_9mm.BP_9mm_C": { name: "9mm" },
  "/Game/Untitled/Objects/ItemDefinitions/Gear/BP_TShirt.BP_TShirt_C": { name: "T-Shirt" },
  "/Game/Untitled/Objects/ItemDefinitions/Gear/BP_CombatGloves.BP_CombatGloves_C": {
    name: "Combat Gloves",
  },
};

export default Items;
