import { useState } from "react";

const { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } = require("reactstrap");

export default function DropDown({
  current,
  options = [],
  onClick = () => null,
  convert = (o) => o,
  disabled = false,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen((prevState) => !prevState)}>
      <DropdownToggle caret size="md" disabled={disabled}>
        {convert(current)}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option) => (
          <DropdownItem key={option} onClick={() => onClick(option)}>
            {convert(option)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
