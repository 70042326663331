import Loader, { LoaderWithScreen } from "Loader";
import api from "api";
import React, { createContext, useState } from "react";
import { Button, Collapse, DropdownToggle } from "reactstrap";
import "./lootTable.scss";
import AutoComplete from "components/AutoComplete";
import items from "loot/items";
import DropDown from "components/DropDown";
import { serverType } from "enums";

const LootOptions = createContext({ loot: [], items: [], categories: [], all: [] });

function passPropsToChildren(children, props) {
  return React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });
}

function EditingComponent({ value, setValue, children }) {
  const [override, setOverride] = React.useState({});
  const [editing, setEditing] = React.useState(false);
  let save = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValue({ ...value, ...override });
    setOverride({});
    setEditing(false);
  };
  let cancel = (e) => {
    e.stopPropagation();
    setOverride({});
    setEditing(false);
  };
  let edit = (e) => {
    e.stopPropagation();
    setEditing(true);
  };
  return (
    <form onSubmit={save}>
      <input className="btn stealth" style={{ display: "none" }} type="submit" value="Apply" />
      {passPropsToChildren(children, {
        edit: {
          save,
          cancel,
          edit,
          editing,
          value: { ...value, ...override },
          override,
          setOverride,
          setValue,
        },
      })}
    </form>
  );
}

function EditField(props) {
  let {
    edit: { value, setOverride, override, editing },
    missing = "",
    field,
    ...extra
  } = props;
  let filledValue = value[field];
  if (filledValue === null || filledValue === undefined) filledValue = missing;
  if (!editing) return filledValue;
  return (
    <input
      type="text"
      {...extra}
      value={filledValue}
      onChange={(e) => setOverride({ ...override, [field]: e.target.value })}
    />
  );
}

function EditLoot(props) {
  let {
    edit: { value, setOverride, override, editing },
    missing = "",
    field,
  } = props;
  let filledValue = value[field];
  if (filledValue === null || filledValue === undefined) filledValue = missing;
  if (!editing) return filledValue;
  return (
    <LootOptions.Consumer>
      {({ all }) => (
        <AutoComplete
          value={filledValue}
          options={all}
          onValue={(value, cb) => setOverride({ ...override, [field]: value }, cb)}
        />
      )}
    </LootOptions.Consumer>
  );
}

function EditToggle(props) {
  let { text = "Edit" } = props;
  let { editing, cancel, edit } = props.edit;
  return (
    <>
      {editing && (
        <Button className="btn stealth" onClick={cancel}>
          Cancel
        </Button>
      )}
      {!editing && (
        <Button className="btn stealth" onClick={edit}>
          {text}
        </Button>
      )}
      &nbsp;
    </>
  );
}

function LootCategoryOption(props) {
  let edit = props.edit;
  let { editing, value: option } = edit;
  option = option || { Loot: "", Weight: 1, Tier: 0, Min: 1, Max: 1 };
  return (
    <>
      T<EditField edit={edit} field="Tier" size="1" missing="0" />
      &nbsp;
      <EditLoot edit={edit} field="Loot" />
      &nbsp;(
      <EditField edit={edit} field="Weight" size="1" missing="1" />
      )&nbsp;
      {editing || ((option.Min || option.Max) && (option.Min != 1 || option.Max != 1)) ? (
        <>
          x<EditField edit={edit} field="Min" size="1" missing="1" />
          {(editing || option.Max > option.Min) && (
            <>
              -<EditField edit={edit} field="Max" size="1" missing="1" />
            </>
          )}
        </>
      ) : null}
      &nbsp;
      <EditToggle edit={edit} />
    </>
  );
}

function LootRoll(props) {
  let edit = props.edit;
  let { editing, value: option } = edit;

  option = option || { Loot: "", Min: 1, Max: 1 };
  return (
    <>
      <EditLoot edit={edit} field="Loot" />
      &nbsp;
      {editing || ((option.Min || option.Max) && (option.Min != 1 || option.Max != 1)) ? (
        <>
          x<EditField edit={edit} field="Min" size="1" />
          {(editing || option.Max > option.Min) && (
            <>
              -<EditField edit={edit} field="Max" size="1" />
            </>
          )}
        </>
      ) : null}
      &nbsp;
      <EditToggle edit={edit} />
    </>
  );
}

function FindName(name, lookup) {
  function FindNameInternal(refs, visited) {
    if (!refs) return false;
    if (refs.includes(name)) return true;
    if (refs.some((r) => r in visited)) return true;
    return refs.some((r) => r in lookup && FindNameInternal(lookup[r], { ...visited, [r]: true }));
  }
  return FindNameInternal(lookup[name], { [name]: true });
}

function LootName(props) {
  return (
    <>
      <LootOptions.Consumer>
        {({ loot, categories, items, refs }) => {
          let name = props.edit.value.Name;
          let collision = name in items || (props.category ? name in loot : name in categories);
          let cycle = FindName(name, refs);
          return (
            <label
              className={collision ? "danger" : cycle ? "warning" : ""}
              onClick={props.edit.editing ? undefined : props.onClick}
            >
              <EditField edit={props.edit} field="Name" />
            </label>
          );
        }}
      </LootOptions.Consumer>
      {props.isOpen && <EditToggle edit={props.edit} />}
      {props.isOpen && (
        <Button
          className="stealth"
          onClick={(e) => {
            props.edit.setValue(null);
          }}
        >
          Delete
        </Button>
      )}
    </>
  );
}

function ListEditEntry(props) {
  return (
    <>
      {passPropsToChildren(props.children, props)}
      {props.i >= 0 && (
        <Button
          className="stealth"
          onClick={() => {
            let result = [...props.values];
            result.splice(props.i, 1);
            props.setOverride(result);
          }}
        >
          Delete
        </Button>
      )}
    </>
  );
}

function LiListEditEntry(props) {
  return (
    <li>
      <ListEditEntry {...props} />
    </li>
  );
}

function ListAddEntry(props) {
  if (props.edit.editing) return <ListEditEntry i={-1} {...props} />;
  return (
    <Button className="stealth" onClick={props.edit.edit}>
      Add
    </Button>
  );
}

function ListEdit({ values, setOverride, children, defaults = {} }) {
  values = values || [];
  return (
    <ul>
      {values.map((item, i) => (
        <EditingComponent
          value={item}
          key={i}
          setValue={(value) => {
            values[i] = value;
            setOverride([...values]);
          }}
        >
          <LiListEditEntry i={i} children={children} setOverride={setOverride} values={values} />
        </EditingComponent>
      ))}
      <li>
        <EditingComponent
          value={defaults}
          setValue={(value) => {
            setOverride([...values, value]);
          }}
        >
          <ListAddEntry children={children} />
        </EditingComponent>
      </li>
    </ul>
  );
}

function LootCategory({ name, options, setOverride }) {
  const [isOpen, setIsOpen] = React.useState(false);
  function rename(value) {
    if (!value) {
      setOverride({ [name]: null });
      return;
    }
    let { Name: newName } = value;
    setOverride({ [name]: null, [newName]: [...options] });
  }
  return (
    <li>
      <EditingComponent value={{ Name: name }} setValue={rename}>
        <LootName category isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </EditingComponent>
      <Collapse isOpen={isOpen}>
        <ListEdit
          defaults={{ Loot: "Loot" }}
          values={options}
          setOverride={(options) => setOverride({ [name]: [...options] })}
        >
          <LootCategoryOption />
        </ListEdit>
      </Collapse>
    </li>
  );
}

function LootSublist({ sub, loot, setOverride }) {
  return (
    <li>
      {sub}
      <ListEdit
        defaults={{ Loot: "Loot", Min: 1, Max: 1 }}
        values={loot[sub]}
        setOverride={(values) => setOverride({ ...loot, [sub]: values })}
      >
        <LootRoll />
      </ListEdit>
    </li>
  );
}

function LootEntry(props) {
  let { name, loot, setOverride } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  function rename(value) {
    if (!value) {
      setOverride({ [name]: null });
      return;
    }
    let { Name: newName } = value;
    setOverride({ [name]: null, [newName]: { ...loot } });
  }
  function itemSetOverride(override) {
    setOverride({ [name]: override });
  }
  return (
    <li>
      <EditingComponent value={{ Name: name }} setValue={rename}>
        <LootName isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </EditingComponent>
      <Collapse isOpen={isOpen}>
        <ul>
          <li>
            <EditingComponent value={loot} setValue={itemSetOverride}>
              Loot: <EditLoot field="Loot" />
              &nbsp;
              <EditToggle />
            </EditingComponent>
          </li>
          <LootSublist sub="Attached" {...props} setOverride={itemSetOverride} />
          <LootSublist sub="Contained" {...props} setOverride={itemSetOverride} />
          <LootSublist sub="Adjacent" {...props} setOverride={itemSetOverride} />
        </ul>
      </Collapse>
    </li>
  );
}

function AddEntry(props) {
  let { name, addItem } = props;
  return (
    <li>
      <EditingComponent value={{ name }} setValue={(value) => addItem(value.name)}>
        <EditField field="name" />
        <EditToggle text="Add" />
      </EditingComponent>
    </li>
  );
}

function GetLootRefs(loot) {
  if (!loot) return null;
  function GetSubRefs(array) {
    return (array && array.map((l) => l.Loot)) || [];
  }
  return [
    loot.Loot,
    ...GetSubRefs(loot.Attached),
    ...GetSubRefs(loot.Contained),
    ...GetSubRefs(loot.Adjacent),
  ];
}
function GetCategoryRefs(options) {
  if (!options) return null;
  return options.filter((o) => !!o).map((o) => o.Loot);
}

function ObjectMap(obj, f) {
  return {
    ...Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, f(value)])),
  };
}

export default function LootTable() {
  const [categoryOverrides, setCategoryOverrides] = React.useState({});
  const [lootOverrides, setLootOverrides] = React.useState({});
  const [type, setType] = React.useState(0);
  let empty =
    Object.keys(categoryOverrides).length === 0 && Object.keys(lootOverrides).length === 0;
  return (
    <Loader refreshId={type} dataSource={() => api.loot.get(type)}>
      {({ loading, data, error, refreshData }) => {
        if (error) return error.Message;
        if (!data) return null;
        let save = async (e) => {
          await api.loot.updateOverride(type, {
            Categories: categoryOverrides,
            Loot: lootOverrides,
          });
          await refreshData();
          revert();
        };
        let revert = (e) => {
          setCategoryOverrides({});
          setLootOverrides({});
        };
        let categories = { ...data.Categories, ...categoryOverrides };
        let loot = { ...data.Loot, ...lootOverrides };
        let lootOptions = [...Object.keys(categories), ...Object.keys(loot), ...items];
        let refs = { ...ObjectMap(categories, GetCategoryRefs), ...ObjectMap(loot, GetLootRefs) };
        return (
          <LootOptions.Provider value={{ loot, categories, items, all: lootOptions, refs }}>
            <h1>
              Loot Tables
              {!empty && (
                <>
                  <Button color="success" onClick={save} disabled={loading}>
                    Save
                  </Button>
                  <Button color="warning" onClick={revert} disabled={loading}>
                    Revert
                  </Button>
                </>
              )}
            </h1>
            <DropDown
              current={type}
              options={[0, 1, 2, 4, 5]}
              convert={serverType}
              onClick={setType}
              disabled={loading}
            />
            <div class="row">
              <div class="col">
                <h2>Categories</h2>
                <ul>
                  {Object.entries(categories).map(
                    ([name, options]) =>
                      options && (
                        <LootCategory
                          key={name}
                          name={name}
                          options={[...options]}
                          setOverride={(override) =>
                            setCategoryOverrides({ ...categoryOverrides, ...override })
                          }
                        />
                      )
                  )}
                  <AddEntry
                    addItem={(name) => setCategoryOverrides({ ...categoryOverrides, [name]: [] })}
                  />
                </ul>
              </div>
              <div class="col">
                <h2>Loot</h2>
                <ul>
                  {Object.entries(loot).map(
                    ([name, loot]) =>
                      loot && (
                        <LootEntry
                          key={name}
                          name={name}
                          loot={loot}
                          setOverride={(override) =>
                            setLootOverrides({ ...lootOverrides, ...override })
                          }
                        />
                      )
                  )}
                  <AddEntry
                    addItem={(name) => setLootOverrides({ ...lootOverrides, [name]: [] })}
                  />
                </ul>
              </div>
            </div>
          </LootOptions.Provider>
        );
      }}
    </Loader>
  );
}
