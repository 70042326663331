import Items from "Items";
import "./inventory.scss";

export function GridInventory({ dims = [1, 1], inventory }) {
  const rows = [...Array(dims[1]).keys()];
  const cols = [...Array(dims[0]).keys()];
  return (
    <div className="col container">
      <h2>Inventory</h2>
      <div className="slot-container">
        {rows.map((x) => (
          <div className="row" key={x}>
            {cols.map((y) => (
              <SlotEntry
                inventory={inventory}
                key={x + y * dims[0]}
                index={x + y * dims[0]}
                slotName="Bag"
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export function PlayerInventory({ inventory }) {
  return (
    <div className="col container">
      <h2>Player Inventory</h2>
      {["Head", "Chest", "Hands", "Legs", "PrimaryWeapon", "Backpack"].map((slot) => (
        <div key={slot} className="margin-border">
          <div className="slot-container">
            <SlotEntry inventory={inventory} slotName={slot} />
          </div>
        </div>
      ))}
    </div>
  );
}

// TODO: Quantity shouldn't be shown for equipment items
export function SlotEntry({ inventory, slotName, index = 0 }) {
  const slot = inventory.Get(slotName, index);
  const bag = slot.tags.has("Bag");
  const classes = "slot" + ((bag && " grid") || "");
  return (
    <div className={classes}>
      {bag ? (
        slot.item ? (
          <ItemEntry item={slot.item}>
            {slot.item && <div className="quantity">{slot.item.Quantity}</div>}
          </ItemEntry>
        ) : (
          <ItemEntry classes="empty" />
        )
      ) : slot.item ? (
        <ItemEntry item={slot.item} />
      ) : (
        <ItemEntry classes="empty">{slotName}</ItemEntry>
      )}
    </div>
  );
}

export function ItemEntry({ item, classes = "", children }) {
  if (!item) return <div className={"item empty"}>{children}</div>;
  const itemDef = (item && item.ItemDefinition) || "";
  const itemName = (Items[itemDef] && Items[itemDef].name) || itemDef;
  const dims = Items[itemDef] && Items[itemDef].dims;
  var style = {};
  if (dims) {
    style.width = "calc(" + dims[0] + "px * 64 - var(--one))";
    style.height = "calc(" + dims[1] + "px * 64 - var(--one))";
    classes += " wide";
  }
  return (
    <div title={itemName} className={"item " + classes} style={style}>
      {itemName}
      {children}
    </div>
  );
}